import {EndpointNameVPSScript} from '@/constants/endpoints'
import {baseApi} from '..'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {
  ArchiveVPSScriptRequest,
  ArchiveVPSScriptResponse,
  ChangeVPSScriptsRunningStatusRequest,
  ChangeVPSScriptsRunningStatusResponse,
  CheckScriptBannedAccountsRequest,
  CheckScriptBannedAccountsResponse,
  ConvertCustomScriptBackRequest,
  ConvertCustomScriptBackResponse,
  ConvertToCustomScriptRequest,
  ConvertToCustomScriptResponse,
  CreateVPSScriptRequest,
  CreateVPSScriptResponse,
  DeleteVPSScriptRequest,
  DeleteVPSScriptResponse,
  GetArchivedVPSScriptsRequest,
  GetArchivedVPSScriptsResponse,
  GetBlacklistedAccountsRequest,
  GetBlacklistedAccountsResponse,
  GetVPSScriptAccountAPILinksRequest,
  GetVPSScriptAccountAPILinksResponse,
  GetVPSScriptAccountsFileDataRequest,
  GetVPSScriptAccountsFileDataResponse,
  GetVPSScriptAccountsRequest,
  GetVPSScriptAccountsResponse,
  GetVPSScriptConfigRequest,
  GetVPSScriptConfigResponse,
  GetVPSScriptHistoryRequest,
  GetVPSScriptHistoryResponse,
  GetVPSScriptLogResultRequest,
  GetVPSScriptLogResultResponse,
  GetVPSScriptsDailyRunStatusRequest,
  GetVPSScriptsDailyRunStatusResponse,
  GetVPSScriptsDailyRunsRequest,
  GetVPSScriptsDailyRunsResponse,
  GetVPSScriptsDefaultProxyRequest,
  GetVPSScriptsDefaultProxyResponse,
  GetVPSScriptsOfDailyRunRequest,
  GetVPSScriptsOfDailyRunResponse,
  GetVPSScriptsRequest,
  GetVPSScriptsResponse,
  GetVPSScriptsTargetsRequest,
  GetVPSScriptsTargetsResponse,
  RunCustomVPSScriptRequest,
  RunCustomVPSScriptResponse,
  RunVPSScriptByUsernameRequest,
  RunVPSScriptByUsernameResponse,
  RunVPSScriptRequest,
  RunVPSScriptResponse,
  SaveVPSScriptAccountAPILinksRequest,
  SaveVPSScriptAccountAPILinksResponse,
  SaveVPSScriptsDefaultProxyRequest,
  SaveVPSScriptsDefaultProxyResponse,
  StartVPSScriptsDailyRunRequest,
  StartVPSScriptsDailyRunResponse,
  UpdateArchivedVPSScriptRequest,
  UpdateArchivedVPSScriptResponse,
  UpdateVPSScriptConfigRequest,
  UpdateVPSScriptConfigResponse,
  UpdateVPSScriptDetailsRequest,
  UpdateVPSScriptDetailsResponse,
  UpdateVPSScriptFileDataByUsernameRequest,
  UpdateVPSScriptFileDataByUsernameResponse,
  UpdateVPSScriptFileDataRequest,
  UpdateVPSScriptFileDataResponse,
  UpdateVPSScriptRequest,
  UpdateVPSScriptResponse,
} from './types'

const VPSScriptsExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getVPSScripts: build.query<GetVPSScriptsResponse, GetVPSScriptsRequest>({
      query: () => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS,
        method: 'GET',
      }),
      providesTags: [RTKTagName.VPS_SCRIPT],
    }),

    getVPSScriptHistory: build.query<GetVPSScriptHistoryResponse, GetVPSScriptHistoryRequest>({
      query: ({id}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_HISTORY + id,
        method: 'GET',
      }),
    }),

    getVPSScriptLogResult: build.query<GetVPSScriptLogResultResponse, GetVPSScriptLogResultRequest>({
      query: ({id}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_LOG_RESULT + id,
        method: 'GET',
      }),
    }),

    createVPSScript: build.mutation<CreateVPSScriptResponse, CreateVPSScriptRequest>({
      query: ({body}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS,
        method: 'POST',
        body,
      }),
    }),

    updateVPSScript: build.mutation<UpdateVPSScriptResponse, UpdateVPSScriptRequest>({
      query: ({id, body}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT + id,
        method: 'PUT',
        body,
      }),
    }),

    deleteVPSScript: build.mutation<DeleteVPSScriptResponse, DeleteVPSScriptRequest>({
      query: ({id}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT + id,
        method: 'DELETE',
      }),
    }),

    updateVPSScriptDetails: build.mutation<UpdateVPSScriptDetailsResponse, UpdateVPSScriptDetailsRequest>({
      query: ({username, body}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT + username,
        method: 'PUT',
        body,
      }),
    }),

    updateVPSScriptFileData: build.mutation<UpdateVPSScriptFileDataResponse, UpdateVPSScriptFileDataRequest>({
      query: ({id, params, body}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT + id,
        method: 'POST',
        params,
        body,
      }),
    }),

    runScript: build.mutation<RunVPSScriptResponse, RunVPSScriptRequest>({
      query: ({id}) => ({
        url: EndpointNameVPSScript.RUN_VPS_SCRIPT + id,
        method: 'POST',
      }),
    }),

    runScriptByUsername: build.mutation<RunVPSScriptByUsernameResponse, RunVPSScriptByUsernameRequest>({
      query: ({username, body}) => ({
        url: EndpointNameVPSScript.RUN_VPS_SCRIPT_BY_USERNAME + username,
        method: 'POST',
        body,
      }),
    }),

    getVPSScriptAccounts: build.query<GetVPSScriptAccountsResponse, GetVPSScriptAccountsRequest>({
      query: ({params}) => ({
        url: EndpointNameVPSScript.VPS_ACCOUNTS,
        method: 'GET',
        params,
      }),
    }),

    updateVPSScriptFileDataByUsername: build.mutation<
      UpdateVPSScriptFileDataByUsernameResponse,
      UpdateVPSScriptFileDataByUsernameRequest
    >({
      query: ({username, params, body}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_USERNAME + username,
        method: 'POST',
        params,
        body,
      }),
    }),

    getVPSScriptsTargets: build.query<GetVPSScriptsTargetsResponse, GetVPSScriptsTargetsRequest>({
      query: () => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_TARGETS,
        method: 'GET',
      }),
    }),

    getVPSScriptAccountsFileData: build.query<
      GetVPSScriptAccountsFileDataResponse,
      GetVPSScriptAccountsFileDataRequest
    >({
      query: ({id, params}) => ({
        url: EndpointNameVPSScript.VPS_ACCOUNTS_FILE_DATA + id,
        method: 'GET',
        params,
      }),
    }),

    runCustomVPSScript: build.mutation<RunCustomVPSScriptResponse, RunCustomVPSScriptRequest>({
      query: ({id, body}) => ({
        url: EndpointNameVPSScript.RUN_VPS_CUSTOM_SCRIPT + id,
        method: 'POST',
        body,
      }),
    }),

    getVPSScriptConfig: build.query<GetVPSScriptConfigResponse, GetVPSScriptConfigRequest>({
      query: ({id}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_CONFIG + id,
        method: 'GET',
      }),
    }),

    getVPSScriptsDailyRunStatus: build.query<GetVPSScriptsDailyRunStatusResponse, GetVPSScriptsDailyRunStatusRequest>({
      query: () => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS_DAILY_RUN_STATUS,
        method: 'GET',
      }),
      providesTags: [RTKTagName.VPS_SCRIPT_DAILY_STATUS],
    }),

    startVPSScriptsDailyRun: build.mutation<StartVPSScriptsDailyRunResponse, StartVPSScriptsDailyRunRequest>({
      query: () => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS_DAILY_RUN_STATUS,
        method: 'POST',
      }),
    }),

    changeVPSScriptsDailyRunStatus: build.mutation<
      ChangeVPSScriptsRunningStatusResponse,
      ChangeVPSScriptsRunningStatusRequest
    >({
      query: ({body}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS_DAILY_RUN_STATUS,
        method: 'PUT',
        body,
      }),
    }),

    getVPSScriptsDailyRuns: build.query<GetVPSScriptsDailyRunsResponse, GetVPSScriptsDailyRunsRequest>({
      query: () => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS_DAILY_RUNS,
        method: 'GET',
      }),
    }),

    getVPSScriptsOfDailyRun: build.query<GetVPSScriptsOfDailyRunResponse, GetVPSScriptsOfDailyRunRequest>({
      query: ({id}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS_OF_DAILY_RUN + id,
        method: 'GET',
      }),
    }),

    saveVPSScriptsDefaultProxy: build.mutation<SaveVPSScriptsDefaultProxyResponse, SaveVPSScriptsDefaultProxyRequest>({
      query: ({body}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS_DEFAULT_PROXY,
        method: 'POST',
        body,
      }),
    }),

    getVPSScriptsDefaultProxy: build.query<GetVPSScriptsDefaultProxyResponse, GetVPSScriptsDefaultProxyRequest>({
      query: () => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS_DEFAULT_PROXY,
        method: 'GET',
      }),
    }),

    getBlacklistedAccounts: build.query<GetBlacklistedAccountsResponse, GetBlacklistedAccountsRequest>({
      query: () => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS_BLACKLISTED_ACCOUNTS,
        method: 'GET',
      }),
    }),

    convertToCustomScript: build.mutation<ConvertToCustomScriptResponse, ConvertToCustomScriptRequest>({
      query: ({scriptName}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS_CONVERT_TO_CUSTOM + scriptName,
        method: 'POST',
      }),
    }),

    convertCustomScriptBack: build.mutation<ConvertCustomScriptBackResponse, ConvertCustomScriptBackRequest>({
      query: ({scriptName}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPTS_CONVERT_CUSTOM_SCRIPT_BACK + scriptName,
        method: 'PUT',
      }),
    }),

    getVPSScriptAccountAPILinks: build.mutation<
      GetVPSScriptAccountAPILinksResponse,
      GetVPSScriptAccountAPILinksRequest
    >({
      query: ({id}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_ACCOUNT_API_LINKS + id,
        method: 'GET',
      }),
    }),

    saveVPSScriptAccountAPILinks: build.mutation<
      SaveVPSScriptAccountAPILinksResponse,
      SaveVPSScriptAccountAPILinksRequest
    >({
      query: ({id, body}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_ACCOUNT_API_LINKS + id,
        method: 'POST',
        body,
      }),
    }),

    archiveVPSScript: build.mutation<ArchiveVPSScriptResponse, ArchiveVPSScriptRequest>({
      query: ({id}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_ARCHIVE + id,
        method: 'DELETE',
      }),
    }),

    updateArchivedVPSScripts: build.mutation<UpdateArchivedVPSScriptResponse, UpdateArchivedVPSScriptRequest>({
      query: ({body}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_ARCHIVED_SCRIPTS,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagName.VPS_SCRIPT],
    }),

    getArchivedVPSScripts: build.query<GetArchivedVPSScriptsResponse, GetArchivedVPSScriptsRequest>({
      query: () => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_ARCHIVED_SCRIPTS,
        method: 'GET',
      }),
    }),

    checkScriptBannedAccounts: build.mutation<CheckScriptBannedAccountsResponse, CheckScriptBannedAccountsRequest>({
      query: ({id}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_CHECK_BANNED_ACCOUNTS + id,
        method: 'POST',
      }),
    }),

    updateVPSScriptConfig: build.mutation<UpdateVPSScriptConfigResponse, UpdateVPSScriptConfigRequest>({
      query: ({id, body}) => ({
        url: EndpointNameVPSScript.VPS_SCRIPT_CONFIG + id,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  useGetVPSScriptsQuery,
  useUpdateVPSScriptDetailsMutation,
  useUpdateVPSScriptFileDataMutation,
  useRunScriptMutation,
  useLazyGetVPSScriptAccountsQuery,
  useUpdateVPSScriptFileDataByUsernameMutation,
  useRunScriptByUsernameMutation,
  useLazyGetVPSScriptHistoryQuery,
  useLazyGetVPSScriptLogResultQuery,
  useGetVPSScriptsTargetsQuery,
  useCreateVPSScriptMutation,
  useUpdateVPSScriptMutation,
  useDeleteVPSScriptMutation,
  useLazyGetVPSScriptAccountsFileDataQuery,
  useRunCustomVPSScriptMutation,
  useLazyGetVPSScriptConfigQuery,
  useGetVPSScriptsDailyRunStatusQuery,
  useStartVPSScriptsDailyRunMutation,
  useChangeVPSScriptsDailyRunStatusMutation,
  useLazyGetVPSScriptsDailyRunsQuery,
  useLazyGetVPSScriptsOfDailyRunQuery,
  useSaveVPSScriptsDefaultProxyMutation,
  useLazyGetVPSScriptsDefaultProxyQuery,
  useLazyGetBlacklistedAccountsQuery,
  useConvertToCustomScriptMutation,
  useConvertCustomScriptBackMutation,
  useGetVPSScriptAccountAPILinksMutation,
  useSaveVPSScriptAccountAPILinksMutation,
  useArchiveVPSScriptMutation,
  useUpdateArchivedVPSScriptsMutation,
  useGetArchivedVPSScriptsQuery,
  useCheckScriptBannedAccountsMutation,
  useUpdateVPSScriptConfigMutation,
} = VPSScriptsExtendedEndpoints
