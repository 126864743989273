/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Props } from './types'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import dayjs from 'dayjs'
import { Loader } from '@/components/common'
import { useLazyGetVPSScriptsOfDailyRunQuery } from '@/features/vps-scripts'
import { useHandleRequest } from '@/hooks/use-handle-request'
import { DailyRunScriptStatus, DailyRunStatus } from '@/constants/status'
import { Check, Clock8, MailWarning } from 'lucide-react'
import { IoWarning } from 'react-icons/io5'
// import {Button} from '@/components/ui/button'
// import {FaCirclePause, FaCirclePlay} from 'react-icons/fa6'
// import {useAppDispatch} from '@/store/hooks'
// import {useToast} from '@/components/ui/use-toast'
// import {RTKTagName} from '@/constants/rtk-tag-name'
// import {baseApi} from '@/features'

export const DailyRunCard: React.FC<Props> = ({ dailyRun, scriptNamesObject }) => {
  // const [changeDailyRunStatus, {isLoading: isChangingDailyRunStatus}] = useChangeVPSScriptsDailyRunStatusMutation()
  const [getVPSScriptsOfDailyRun, { data: { data: scripts = [] } = {}, isFetching }] = useLazyGetVPSScriptsOfDailyRunQuery()
  const [fetched, setFetched] = useState(false)
  const [open, setOpen] = useState(false)
  // const dispatch = useAppDispatch()
  const handleRequest = useHandleRequest()
  // const {toast} = useToast()

  const fetchVPSScriptsOfDailyRun = async () => {
    await handleRequest({
      request: async () => {
        const result = await getVPSScriptsOfDailyRun({ id: dailyRun.dateName })
        return result
      },
      onSuccess: () => {
        setFetched(true)
      },
    })
  }

  // const onChangeDailyRunStatus = async (status: DailyRunStatus.RUNNING | DailyRunStatus.PAUSED) => {
  //   await handleRequest({
  //     request: async () => {
  //       const result = await changeDailyRunStatus({
  //         body: {
  //           status,
  //         },
  //       })
  //       return result
  //     },
  //     onSuccess: () => {
  //       dispatch(baseApi.util.invalidateTags([RTKTagName.VPS_SCRIPT_DAILY_STATUS]))
  //       toast({title: 'Success', description: 'Daily run status successfully changed!'})
  //     },
  //   })
  // }

  useEffect(() => {
    if (open && !fetched) {
      fetchVPSScriptsOfDailyRun()
    }
  }, [open])

  return (
    <Accordion type="multiple" className="w-full">
      <AccordionItem onClick={() => setOpen(prev => !prev)} value="accounts" className="mb-3 border rounded">
        <AccordionTrigger
          // rightContent={
          //   dailyRun.isInProgress && (
          //     <div onClick={e => e.preventDefault()}>
          //       {
          //         {
          //           [DailyRunStatus.RUNNING]: (
          //             <Button
          //               loading={isChangingDailyRunStatus}
          //               onClick={() => onChangeDailyRunStatus(DailyRunStatus.PAUSED)}
          //               variant="outlined-destructive"
          //               size="sm"
          //               className="flex items-center gap-2 min-w-max"
          //               asChildTag="div"
          //             >
          //               <FaCirclePause />
          //               <span>Pause</span>
          //             </Button>
          //           ),
          //           [DailyRunStatus.PAUSED]: (
          //             <Button
          //               loading={isChangingDailyRunStatus}
          //               onClick={() => onChangeDailyRunStatus(DailyRunStatus.RUNNING)}
          //               variant="outline"
          //               size="sm"
          //               className="flex items-center gap-2 min-w-max"
          //               asChildTag="div"
          //             >
          //               <FaCirclePlay />
          //               <span>Continue Running</span>
          //             </Button>
          //           ),
          //         }[dailyRun.status as unknown as string]
          //       }
          //     </div>
          //   )
          // }
          className="px-6 hover:no-underline [&[data-state=open]]:border-b"
        >
          <div className="flex items-center gap-3">
            <span>{dayjs(dailyRun.date).format('HH:mm')}</span>
            {dailyRun.status === DailyRunStatus.RUNNING && <Loader className="!w-5 !h-5 border-2" />}
          </div>
        </AccordionTrigger>
        <AccordionContent className="px-6 py-4">
          {isFetching ? (
            <Loader parentClassName="py-20" />
          ) : scripts.length ? (
            <div className="space-y-2">
              {scripts.map(script => (
                <div key={script.scriptName} className="border rounded-sm px-4 py-2">
                  <div className="flex items-center justify-between">
                    <span>{scriptNamesObject[script.scriptName]}</span>
                    {
                      {
                        [DailyRunScriptStatus.PENDING]: (
                          <div className="flex items-center gap-1 text-xs bg-sky-600 px-3 py-1.5 rounded">
                            <Clock8 className="w-3.5 h-3.5" />
                            <span>Waiting</span>
                          </div>
                        ),
                        [DailyRunScriptStatus.RUNNING]: (
                          <div className="flex items-center gap-1.5 text-xs bg-blue-600 px-3 py-1.5 rounded">
                            <Loader className="!bg-white !w-3 !h-3 !p-1.5" parentClassName="!bg-transparent" />
                            <span>Running</span>
                          </div>
                        ),
                        [DailyRunScriptStatus.FAILED]: (
                          <div className="flex items-center gap-1.5 text-xs bg-red-600 px-3 py-1.5 rounded">
                            <IoWarning className="w-3.5 h-3.5" />
                            <span>Failed</span>
                          </div>
                        ),
                        [DailyRunScriptStatus.COMPLETED]: (
                          <div className="flex items-center gap-1.5 text-xs bg-green-600 px-3 py-1.5 rounded">
                            <Check className="w-3.5 h-3.5" />
                            <span>Completed</span>
                          </div>
                        ),
                      }[script.status]
                    }
                  </div>
                  {script.status === DailyRunScriptStatus.FAILED && script.message && (
                    <div className="flex items-center gap-2 text-xs text-red-600 font-bold mt-2">
                      <MailWarning className="w-3.5 h-3.5" />
                      <span>{script.message}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-sm text-slate-500 py-16 text-center">No any daily running scripts found</div>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
