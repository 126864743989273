/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEventHandler, useEffect, useState } from 'react'
import { Props } from './types'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { useHandleRequest } from '@/hooks/use-handle-request'
import { useLazyGetVPSScriptLogResultQuery } from '@/features/vps-scripts'
import { Loader } from '@/components/common'
import { Button } from '@/components/ui/button'
import { ExternalLink } from 'lucide-react'

export const AccounResult: React.FC<Props> = ({ result }) => {
  const [getLogResult, { data: { data: { failed = [], processed = [] } = {} } = {}, isLoading }] =
    useLazyGetVPSScriptLogResultQuery()
  const [open, setOpen] = useState(false)
  const [fetched, setFetched] = useState(false)
  const handleRequest = useHandleRequest()

  const onRedirectResult: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    window.open(`http://157.90.215.58:5008/batch/${result.id}`)
  }

  const fetchLogResult = async () => {
    await handleRequest({
      request: async () => {
        const fetchResult = await getLogResult({ id: result.id })
        return fetchResult
      },
      onSuccess: () => {
        setFetched(true)
      },
    })
  }

  useEffect(() => {
    if (open && !fetched) {
      fetchLogResult()
    }
  }, [open])

  return (
    <AccordionItem key={result.id} value={result.id} className="mb-3 border rounded">
      <AccordionTrigger
        onClick={() => setOpen(prev => !prev)}
        className="px-6 hover:no-underline [&[data-state=open]]:border-b"
        rightContent={
          <Button asChildTag="div" onClick={onRedirectResult} size="sm" variant="outline">
            <ExternalLink className="w-3.5 h-3.5" />
          </Button>
        }
      >
        <span>
          {result.account}{' '}
          {result.success === undefined ? (
            ''
          ) : result.success ? (
            <span className="text-xs bg-green-600 ml-3 px-2 pt-0.5 pb-1 rounded">success</span>
          ) : (
            <span className="text-xs bg-red-600 ml-3 px-2 pt-0.5 pb-1 rounded">failed</span>
          )}
        </span>
      </AccordionTrigger>
      <AccordionContent className="px-6 py-4">
        {isLoading ? (
          <Loader parentClassName="py-5" />
        ) : (
          <div>
            <Accordion type="multiple">
              <AccordionItem value="failed" className='className="mb-3 border rounded"'>
                <AccordionTrigger className="px-6 hover:no-underline [&[data-state=open]]:border-b">
                  Faileds ({failed.length})
                </AccordionTrigger>
                <AccordionContent className="px-6 py-4">
                  {!failed.length ? (
                    <div className="text-sm text-slate-600 text-center py-7">No any failed usernames</div>
                  ) : (
                    <div>
                      {failed.map(username => (
                        <div key={username}>{username}</div>
                      ))}
                    </div>
                  )}
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="proceed" className='className="mb-3 border rounded"'>
                <AccordionTrigger className="px-6 hover:no-underline [&[data-state=open]]:border-b">
                  Processeds ({processed.length})
                </AccordionTrigger>
                <AccordionContent className="px-6 py-4">
                  {!processed.length ? (
                    <div className="text-sm text-slate-600 text-center py-7">No any processed usernames</div>
                  ) : (
                    <div className="grid grid-cols-2 gap-2">
                      {processed.map(username => (
                        <div key={username}>{username}</div>
                      ))}
                    </div>
                  )}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        )}
      </AccordionContent>
    </AccordionItem>
  )
}
