import React, {ChangeEvent, useState} from 'react'
import {Props} from './types'
import {Modal, Textarea} from '@/components/common'
import {Button} from '@/components/ui/button'
import {IoMdClose} from 'react-icons/io'
import {useCreateProfilePostsMutation} from '@/features/profile'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'

export const CreatePostsModal: React.FC<Props> = ({open, close, profile}) => {
  const [createPost] = useCreateProfilePostsMutation()
  const [posts, setPosts] = useState<File[]>([])
  const [loading, setLoading] = useState(false)
  const [captions, setCaptions] = useState('')
  const handleRequest = useHandleRequest()
  const {toast} = useToast()

  const onFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setPosts(prev => [...prev, ...(event.target.files || [])])
    const fileNames = Array.from(event.target.files || [])
      .map(file => file.name.slice(0, file.name.lastIndexOf('.')))
      .join('\n')
    setCaptions(prev => (prev ? `${prev}\n${fileNames}` : (fileNames as any)))

    event.target.value = ''
  }

  const onRemovePost = (index: number) => {
    setPosts(prev => {
      const updatedPosts = [...prev]
      updatedPosts.splice(index, 1)
      return updatedPosts
    })
  }

  const onSubmit = async () => {
    setLoading(true)

    const captionsArray = captions.split('\n')
    let success = false

    for (let i = 0; i < posts.length; i++) {
      await handleRequest({
        request: async () => {
          const formData = new FormData()
          formData.append('caption', captionsArray[i])
          formData.append('file', posts[i])

          const result = await createPost({id: profile._id, body: formData})
          return result
        },
        onSuccess: () => {
          success = true
        },
      })
    }

    if (success) {
      toast({title: 'Posts successfully created!'})
      setPosts([])
      setCaptions('')
      close()
    }

    setLoading(false)
  }

  return (
    <Modal
      open={open}
      close={close}
      title={`Create Posts for ${profile.phoneID} phone`}
      contentClassName="!min-w-[600px]"
    >
      <div className="grid grid-cols-2 gap-10">
        <div>
          <div className="flex flex-col gap-2 mb-5 text-sm">
            {posts.map((post, index) => (
              <div key={index} className="flex items-center justify-between">
                <span className="line-clamp-1 w-full">{post.name}</span>{' '}
                <div className="flex items-center gap-1">
                  <span className="min-w-max">- {(post.size / 1000000).toFixed(1)} MB</span>
                  <button onClick={() => onRemovePost(index)} type="button">
                    <IoMdClose />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <label className="border border-dashed rounded-lg h-20 grid place-content-center text-slate-400 text-xs">
            <span>Upload Files</span>
            <input type="file" hidden multiple onChange={onFileUpload} />
          </label>
        </div>
        <div className="h-full">
          <Textarea
            label="Captions"
            placeholder="Enter captions..."
            className="h-full"
            parentClassName="h-full"
            value={captions}
            onChange={event => setCaptions(event.target.value)}
          />
        </div>
      </div>
      <Button loading={loading} className="w-full mt-5" onClick={onSubmit} disabled={!posts.length}>
        Create Posts
      </Button>
    </Modal>
  )
}
