/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {BaseScriptFormData, Props} from './types'
import {Button} from '@/components/ui/button'
import {
  useConvertCustomScriptBackMutation,
  useConvertToCustomScriptMutation,
  useRunCustomVPSScriptMutation,
  useRunScriptByUsernameMutation,
  useUpdateVPSScriptConfigMutation,
} from '@/features/vps-scripts'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {AccordionContent} from '@/components/ui/accordion'
import {AccountsCard, CustomScriptAccountsCard, DMWarningModal, PKSCard, ProfilesCard, RunHistory} from './components'
import {useDispatch} from 'react-redux'
import {baseApi} from '@/features'
import {RTKTagName} from '@/constants/rtk-tag-name'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Form} from '@/components/ui/form'
import {DeleteModal, Field, Loader, Modal} from '@/components/common'
import {getValidations} from '@/utils/util-functions'
import {History, Snowflake} from 'lucide-react'
import {getRandomDM} from '@/utils/dm'

export const ScriptContent: React.FC<Props> = ({script, isTargetsLoading, targets, isLoading, config}) => {
  const [updateScriptConfig, {isLoading: isUpdatingScriptConfig}] = useUpdateVPSScriptConfigMutation()
  const [convertToCustom, {isLoading: isConvertingToCustom}] = useConvertToCustomScriptMutation()
  const [convertCustomScriptBack, {isLoading: isConvertingBack}] = useConvertCustomScriptBackMutation()
  const [runCustomScript, {isLoading: isRunningCustomScript}] = useRunCustomVPSScriptMutation()
  const [runScript, {isLoading: isRunngingScript}] = useRunScriptByUsernameMutation()
  const form = useForm<BaseScriptFormData>()
  const [openHistory, setOpenHistory] = useState(false)
  const [openConvertToCustomScriptConfirm, setOpenConvertToCustomScriptConfirm] = useState(false)
  const handleRequest = useHandleRequest()
  const dispatch = useDispatch()
  const {toast} = useToast()
  const [dmWarning, setDMWarning] = useState(false)
  const proxy = form.watch('proxy')
  const dmEnabled = form.watch('dmEnabled')
  const [openTest, setOpenTest] = useState(false)

  const onRunScript: SubmitHandler<BaseScriptFormData> = async formData => {
    const hasDMError =
      Boolean(dmEnabled) && (script._id || script.converted)
        ? getRandomDM(formData.dmFormats)
          ? undefined
          : 'DM is in invalid format!'
        : undefined

    if (hasDMError) {
      setDMWarning(true)
      return
    }

    await handleRequest({
      request: async () => {
        let result
        if (script._id) {
          result = await runCustomScript({
            id: script._id,
            body: {
              maxBatchSize: +formData.maxBatchSize,
              timeBetweenSends: +formData.timeBetweenSends,
              country: formData.country,
              proxy: formData.proxy,
              proxyRotationLink: formData.proxyRotationLink,
              dmEnabled: formData.dmEnabled,
              ...(formData.dmEnabled ? {dmFormats: formData.dmFormats} : {}),
            },
          })
        } else {
          result = await runScript({
            username: script.username,
            body: {
              maxBatchSize: +formData.maxBatchSize,
              timeBetweenSends: +formData.timeBetweenSends,
              country: formData.country,
              profiles: script.profiles.join(','),
              proxy: formData.proxy,
              proxyRotationLink: formData.proxyRotationLink,
              dmEnabled: formData.dmEnabled,
              ...(formData.dmEnabled && script.converted ? {dmFormats: formData.dmFormats} : {}),
              converted: script.converted,
            },
          })
        }

        return result
      },
      onSuccess: () => {
        dispatch(baseApi.util.invalidateTags([RTKTagName.VPS_SCRIPT]))
        toast({title: 'Script successfully running!'})
      },
    })
  }

  const onConvertToCustom = async () => {
    await handleRequest({
      request: async () => {
        const result = await convertToCustom({scriptName: script.username})
        return result
      },
      onSuccess: () => {
        dispatch(baseApi.util.invalidateTags([RTKTagName.VPS_SCRIPT]))
        toast({title: 'Script successfully convert to custom!'})
      },
    })
  }

  const onConvertCustomScriptBack = async () => {
    await handleRequest({
      request: async () => {
        const result = await convertCustomScriptBack({scriptName: script.username})
        return result
      },
      onSuccess: () => {
        dispatch(baseApi.util.invalidateTags([RTKTagName.VPS_SCRIPT]))
        toast({title: 'Script successfully convert back to original status!'})
      },
    })
  }

  const onSaveConfigs = async () => {
    const formData = form.getValues()
    const validationResult = await form.trigger()

    if (!validationResult) {
      return
    }

    const hasDMError =
      Boolean(dmEnabled) && (script._id || script.converted)
        ? getRandomDM(formData.dmFormats)
          ? undefined
          : 'DM is in invalid format!'
        : undefined

    if (hasDMError) {
      setDMWarning(true)
      return
    }

    await handleRequest({
      request: async () => {
        const result = updateScriptConfig({
          id: script._id || script.username,
          body: {
            config: {
              maxBatchSize: +formData.maxBatchSize,
              timeBetweenSends: +formData.timeBetweenSends,
              country: formData.country,
              proxy: formData.proxy,
              proxyRotationLink: formData.proxyRotationLink,
              dmEnabled: formData.dmEnabled,
              ...((script._id || script.converted) && formData.dmEnabled ? {dmFormats: formData.dmFormats} : {}),
              ...(!script._id ? {profiles: script.profiles.join(','), converted: script.converted} : {}),
            },
          },
        })
        return result
      },
      onSuccess: () => {
        toast({title: 'Script configs successfully saved!'})
      },
    })
  }

  useEffect(() => {
    form.setValue('country', config.country)
    form.setValue('dmEnabled', config.dmEnabled)
    form.setValue('dmFormats', config.dmFormats)
    form.setValue('maxBatchSize', config.maxBatchSize)
    form.setValue('timeBetweenSends', config.timeBetweenSends)
    form.setValue('proxy', config.proxy)
    form.setValue('proxyRotationLink', config.proxyRotationLink)
  }, [config])

  if (isLoading) {
    return (
      <AccordionContent>
        <Loader parentClassName="py-10" />
      </AccordionContent>
    )
  }

  return (
    <>
      <AccordionContent className="px-6 py-4">
        <div className="flex items-center justify-end gap-2">
          <Button onClick={() => setOpenHistory(true)} variant="ghost" className="mb-4 flex items-center gap-2">
            <span>Run History</span>
            <History className="w-4 h-4" />
          </Button>
          {!script._id &&
            (script.converted ? (
              <Button
                onClick={onConvertCustomScriptBack}
                loading={isConvertingBack}
                variant="outline"
                className="mb-4 flex items-center gap-2"
              >
                <span>Unfreeze Accounts</span>
                <Snowflake className="w-4 h-4" />
              </Button>
            ) : (
              <Button
                onClick={() => setOpenConvertToCustomScriptConfirm(true)}
                variant="outline"
                className="mb-4 flex items-center gap-2"
              >
                <span>Freeze Accounts</span>
                <Snowflake className="w-4 h-4" />
              </Button>
            ))}
        </div>
        {!script._id && <ProfilesCard profiles={script.profiles} />}
        {script._id || script.converted ? (
          <CustomScriptAccountsCard script={script} />
        ) : (
          <AccountsCard script={script} />
        )}
        <PKSCard script={script} isTargetsLoading={isTargetsLoading} targets={targets} />

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onRunScript)} className="relative flex flex-col gap-4 mt-5">
            {script.running && (
              <div className="absolute top-0 left-0 w-full h-full bg-background/80 z-10 grid place-content-center text-slate-500">
                Script is running!
              </div>
            )}
            <Field
              type="input"
              control={form.control}
              name="timeBetweenSends"
              rules={getValidations(true, false, false, {
                validate: (value: string) =>
                  isNaN(+value)
                    ? 'Please enter correct number'
                    : +value > 1000
                    ? 'Sleep time is too long'
                    : +value < 1
                    ? 'Sleep time is too short'
                    : undefined,
              })}
              componentProps={{
                type: 'number',
                label: 'Sleep time (in seconds)',
                placeholder: '60',
                labelClassName: 'mb-1.5',
              }}
            />
            <Field
              type="input"
              control={form.control}
              name="maxBatchSize"
              rules={getValidations(true, false, false, {
                validate: (value: string) => (isNaN(+value) ? 'Please enter correct number' : undefined),
              })}
              componentProps={{
                type: 'number',
                label: 'Max batch size',
                placeholder: '99',
                labelClassName: 'mb-1.5',
              }}
            />
            <Field
              type="input"
              control={form.control}
              name="country"
              rules={getValidations(true)}
              componentProps={{
                type: 'string',
                label: 'Registration Country',
                placeholder: 'CA',
                labelClassName: 'mb-1.5',
              }}
            />
            <Field
              type="input"
              control={form.control}
              name="proxy"
              componentProps={{
                label: 'Proxy',
                placeholder: 'proxy link...',
                labelClassName: 'mb-1.5',
              }}
            />
            {proxy && (
              <Field
                type="input"
                control={form.control}
                name="proxyRotationLink"
                rules={getValidations(Boolean(proxy))}
                componentProps={{
                  label: 'Proxy rotation link',
                  placeholder: 'Enter proxy rotation link...',
                  labelClassName: 'mb-1.5',
                }}
              />
            )}
            <Field type="switch" control={form.control} name="dmEnabled" componentProps={{label: 'Send DMs'}} />
            {(script._id || script.converted) && dmEnabled && (
              <div>
                <Field
                  type="textarea"
                  control={form.control}
                  name="dmFormats"
                  componentProps={{label: 'DM Format', placeholder: 'dm format...', className: 'h-[200px]'}}
                  // rules={getValidations(Boolean(dmEnabled), false, false, {validate: (dmFormats: string) => })}
                />
              </div>
            )}
            <div className="flex items-center gap-3">
              <Button
                disabled={script.running}
                loading={isRunngingScript || isRunningCustomScript}
                htmlType="submit"
                size="sm"
                className="w-full text-xs mt-2"
                loadingText="Script is running"
              >
                Run Script
              </Button>
              <Button
                onClick={onSaveConfigs}
                loading={isUpdatingScriptConfig}
                size="sm"
                className="w-full text-xs mt-2"
                loadingText="Configs are saving"
                variant="outline"
              >
                Save Configs
              </Button>
            </div>
          </form>
        </Form>
        <RunHistory open={openHistory} close={() => setOpenHistory(false)} script={script} />
        <DeleteModal
          title="Are you sure ?"
          description={`Are you sure to convert this script to custom script (${script.username}) ?`}
          open={openConvertToCustomScriptConfirm}
          close={() => setOpenConvertToCustomScriptConfirm(false)}
          onDelete={onConvertToCustom}
          deleteButtonText="Convert"
          isDeleting={isConvertingToCustom}
        />
      </AccordionContent>
      <DMWarningModal open={dmWarning} close={() => setDMWarning(false)} />
      <Modal open={openTest} close={() => setOpenTest(false)} title="">
        <div className="flex flex-col items-center">
          <h2 className="text-7xl">✋</h2>
          <h2 className="text-2xl mt-3 mb-2 font-bold">Hold on!</h2>
          <p className="text-xs text-center">
            There're some development changes happening, unfortunately you can't run scripts meantime, please contact
            with the developer to know the status of development
          </p>
          <Button onClickCapture={() => setOpenTest(false)} className="mt-5 w-full" onClick={close}>
            Okay
          </Button>
        </div>
      </Modal>
    </>
  )
}
