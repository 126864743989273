export enum EndpointNameAuth {
  LOGIN_ACCOUNT = '/auth/login/account',
  LOGIN_SUBACCOUNT = '/auth/login/subaccount',
  GET_USER = '/auth/me',
}

export enum EndpointNameProfile {
  GET_PROFILES_AND_GROUPS = '/profiles',
  GET_REVIEW_PROFILES = '/profiles/review',
  GET_ALL_PROFILES = '/profiles/all',
  GET_PROFILE_REPORT = '/profiles/report/',
  PROFILE_PACKAGE = '/profiles/package/',
  GET_PROFILE_CHILD_ACCOUNTS = '/profiles/child-accounts/',
  PROFILE = '/profiles/',
  UPDATE_PROFILE_DETAILS = '/profiles/details/',
  UPDATE_PROFILE_DATA = '/profiles/data/',
  CREATE_PROFILE = '/profiles/create',
  CSV = '/profiles/csv/',
  IMAGES = '/profiles/images/',
  POSTS = '/profiles/posts/',
  ACCOUNTS = '/profiles/accounts/',
}

export enum EndpointNameProfileGroup {
  GET_ALL_GROUPS = '/profile-groups/all',
  GROUP = '/profile-groups/',
  CREATE_GROUP = '/profile-groups/create',
}

export enum EndpointNameProfileNiche {
  GET_NICHES = '/profile-niches',
  GET_NICHE_PROFILES = '/profile-niches/profiles/',
  CREATE_NICHE = '/profile-niches/create',
  NICHE = '/profile-niches/',
}

export enum EndpointNameProfileDM {
  PROFILE_DM = '/profile-dms/',
  CREATE_DM = '/profile-dms/create/',
  ACTIVATE_DM = '/profile-dms/activate/',
  SEARCH = '/profile-dms/search',
  GET_ALL_USERNAMES = '/profile-dms/get-all',
}

export enum EndpointNameScrapingTask {
  GET_PROFILE_TASKS = '/scraping-tasks/',
  CREATE_TASK = '/scraping-tasks/create',
  GET_PROFILE_ALL_TASKS = '/scraping-tasks',
  DELETE_FROM_SCRAPER = '/scraping-tasks/scraper/',
  DELETE_FROM_BOT_SERVER = '/scraping-tasks/bot-server/',
  COMPLETE_TASK = '/scraping-tasks/complete/',
  RESTORE_TASK = '/scraping-tasks/restore/',
  CLONE_TASK = '/scraping-tasks/clone/',
  GET_BLACKLISTED_USERNAMES = '/scraping-tasks/blacklist/',
  UPLOAD_FILE_TO_BLACKLIST = '/scraping-tasks/upload/',
}

export enum EndpointNameAccount {
  GET_ACCOUNTS = '/accounts',
  CREATE_ACCOUNT = '/accounts/create',
  ACCOUNT = '/accounts/',
}

export enum EndpointNameSubaccount {
  GET_SUBACCOUNTS = '/subaccounts',
  CREATE_SUBACCOUNT = '/subaccounts/create',
  SUBACCOUNT = '/subaccounts/',
}

export enum EndpointNameClientProfile {
  GET_CLIENT_PROFILES_AND_GROUPS = '/client-profiles',
  ALL_CLIENT_PROFILES = '/client-profiles/all',
  CLIENT_PROFILE = '/client-profiles/',
  BLACKLIST = '/client-profiles/blacklist',
  SEND_CLIENT_REPORT = '/client-profiles/report/',
}

export enum EndpointNameClientGroup {
  CLIENT_GROUPS = '/client-groups',
  CLIENT_GROUP = '/client-groups/',
  SEND_CLIENT_GROUP_REPORT = '/client-groups/report/',
}

export enum EndpointNameRoadmapTasks {
  ROADMAP_TASKS = '/roadmap-tasks',
  ROADMAP_TASK = '/roadmap-tasks/',
  ROADMAP_TASK_ORDER = '/roadmap-tasks/order/',
}

export enum EndpointNameRoadmapNotes {
  ROADMAP_NOTES = '/roadmap-notes',
  ROADMAP_NOTE = '/roadmap-notes/',
}

export enum EndpointNameGrowthTracker {
  GROWTH_TRACKER_DATA = '/growth-tracker',
}

export enum EndpointNameStoppedPhone {
  STOPPED_PHONES = '/stopped-phones',
  STOPPED_PHONE = '/stopped-phones/',
}

export enum EndpointNameVPSScript {
  VPS_SCRIPTS = '/vps-scripts',
  VPS_SCRIPT = '/vps-scripts/',
  VPS_SCRIPT_TARGETS = '/vps-scripts/targets',
  VPS_SCRIPT_HISTORY = '/vps-scripts/history/',
  VPS_SCRIPT_LOG_RESULT = '/vps-scripts/history/log/',
  VPS_SCRIPT_USERNAME = '/vps-scripts/username/',
  RUN_VPS_SCRIPT = '/vps-scripts/run/',
  RUN_VPS_CUSTOM_SCRIPT = '/vps-scripts/run-custom/',
  RUN_VPS_SCRIPT_BY_USERNAME = '/vps-scripts/run-by-username/',
  VPS_ACCOUNTS = '/vps-scripts/accounts',
  VPS_ACCOUNTS_FILE_DATA = '/vps-scripts/accounts/',
  VPS_SCRIPT_CONFIG = '/vps-scripts/configs/',
  VPS_SCRIPTS_DAILY_RUN_STATUS = '/vps-scripts/daily',
  VPS_SCRIPTS_DAILY_RUNS = '/vps-scripts/daily-runs',
  VPS_SCRIPTS_OF_DAILY_RUN = '/vps-scripts/scripts-of-daily-run/',
  VPS_SCRIPTS_DEFAULT_PROXY = '/vps-scripts/default-proxy/',
  VPS_SCRIPTS_BLACKLISTED_ACCOUNTS = '/vps-scripts/accounts/blacklist',
  VPS_SCRIPTS_CONVERT_TO_CUSTOM = '/vps-scripts/convert/',
  VPS_SCRIPTS_CONVERT_CUSTOM_SCRIPT_BACK = '/vps-scripts/convert-back/',
  VPS_SCRIPT_ACCOUNT_API_LINKS = '/vps-scripts/account-api-links/',
  VPS_SCRIPT_ARCHIVE = '/vps-scripts/archive/',
  VPS_SCRIPT_ARCHIVED_SCRIPTS = '/vps-scripts/archive',
  VPS_SCRIPT_CHECK_BANNED_ACCOUNTS = '/vps-scripts/accounts/check/',
}

export enum EndpointNameVPSScraperTask {
  VPS_SCRAPER_TASKS = '/vps-scraper-tasks',
  VPS_SCRAPER_TASK = '/vps-scraper-tasks/',
  VPS_SCRAPER_TASK_CHECK = '/vps-scraper-tasks/check/',
}
